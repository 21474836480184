<template>
  <div class="container-xl">
    <div class="card col-lg-12 mt-2">
      <div class="card-header border-0 justify-content-between">
        <div class="card-title">
          EWA Report
        </div>
        <div class="flex flex-row mb-2 pr-2 float-right">
          <el-date-picker
              v-model="search.date_filter"
              type="daterange"
              size="small"
              :picker-options="datePickerOptions"
              class="mr-2"
              range-separator="To"
              value-format="yyyy-MM-dd"
              start-placeholder="Start date"
              end-placeholder="End date">
            </el-date-picker>
          <el-button :loading="isLoading" type="info" @click="searchHandler" size="small"><font-awesome-icon icon="search"/> Search</el-button>
          <el-button :loading="isLoading" type="primary" @click="downloadHandler" size="small"><font-awesome-icon icon="download"/> Download</el-button>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table card-table text-nowrap table-vcenter">
          <thead>
            <tr>
              <th>Disbursement Date</th>
              <th>Employee Name</th>
              <th>Company Name</th>
              <th>Disbursement Amount</th>
              <th>Admin Fee</th>
              <th>Total Transfer</th>
              <th>Invoice Status</th>
              <th>Due Date Invoice</th>
              <th>Total Payment</th>
              <th>Payment Date</th>
            </tr>
          </thead>
          <tbody v-loading="isLoading">
            <tr v-for="(item, i) in list" :key="i">
              <td>{{ item.disburse_at_str }}</td>
              <td>{{ item.employee_name }}</td>
              <td>{{ item.company_name }}</td>
              <td>{{ item.request_amount_str }}</td>
              <td>{{ item.admin_fee_str }}</td>
              <td>{{ item.total_transfer_str }}</td>
              <td>
                <span class="tag" :class="item.invoice_status_color">{{ item.invoice_status_str }}</span>
              </td>
              <td>{{ item.invoice_due_date_str }}</td>
              <td>{{ item.request_amount_str }}</td>
              <td>{{ item.invoice_payment_date_str }}</td>
            </tr>
          </tbody>
        </table>
        <el-empty description="No report" v-if="total_rows == 0" :image="emptyStateImage"></el-empty>
      </div>
      <pagination :list="list"
        :total_rows="total_rows"
        :page="search.page"
        :per_page="search.per_page"
        @pageChange="pageChangeHandler"
        />
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import emptyStateImage from '@/assets/images/empty-state.png';
import {
  GET_EWA_REPORT,
} from '../../../store/modules/ewa';
import Pagination from '../../components/Pagination.vue';

const invoice_status = {
  waiting_payment: {
    label: 'Waiting Payment',
    color: 'tag-warning',
  },
  partial_paid: {
    label: 'Partial Paid',
    color: 'tag-info',
  },
  paid: {
    label: 'Paid',
    color: 'tag-success',
  },
  cancelled: {
    label: 'Cancelled',
    color: 'tag-danger',
  },
};

export default {
  components: { Pagination },
  name: 'Report',
  metaInfo: {
    title: 'Early Wage Access Report',
  },
  data() {
    return {
      emptyStateImage,
      search: {
        date_filter: [
          moment().subtract(1, 'month').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
        page: 1,
        per_page: 20,
      },
      isLoading: false,
      datePickerOptions: {
        disabledDate: (date) => moment(date).isAfter(moment(), 'days'),
      },
      list: [],
      total_rows: 0,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    pageChangeHandler(page) {
      this.search.page = page;
      this.getList();
    },
    searchHandler() {
      this.getList();
    },
    async downloadHandler() {
      this.isLoading = true;
      this.search.action = 'download';
      await this.$store.dispatch(GET_EWA_REPORT, this.search).then((result) => {
        const anchor = document.createElement('a');
        anchor.href = `data:${result.headers['content-type']},${encodeURIComponent(result.data)}`;
        anchor.target = '_blank';
        anchor.download = `EWA-Report-${this.search.date_filter.join('-')}.csv`;
        anchor.click();
      });
      this.isLoading = false;
    },
    async getList() {
      this.isLoading = true;
      delete this.search.action;
      const { data: response } = await this.$store.dispatch(GET_EWA_REPORT, this.search);
      const { count, rows } = response.data;
      this.isLoading = false;
      this.list = rows.map((v) => {
        v.disburse_at_str = moment(v.disburse_at).format('DD MMM YYYY, HH:mm');
        v.employee_name = v.requestor.full_name;
        v.company_name = v.company.name;
        v.total_transfer = v.request_amount - v.admin_fee;
        ['request_amount', 'admin_fee', 'total_transfer'].forEach((key) => {
          v[`${key}_str`] = `Rp. ${v[key] ? v[key].toLocaleString() : 0}`;
        });
        v.invoice_status_str = invoice_status[v.invoice.status].label;
        v.invoice_status_color = invoice_status[v.invoice.status].color;
        v.invoice_due_date_str = v.invoice.ewa_invoice.due_date ? moment(v.invoice.ewa_invoice.due_date).format('DD MMM YYYY') : '-';
        v.invoice_payment_date_str = v.invoice.ewa_invoice.paid_at ? moment(v.invoice.ewa_invoice.paid_at).format('DD MMM YYYY') : '-';
        return v;
      });
      this.total_rows = count;
    },
  },
};
</script>
